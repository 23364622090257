
import { Component, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { Table as ElTable, TableColumn as ElTableColumn} from 'element-ui';
import VueSlider from 'vue-slider-component';
import { Splide, SplideSlide} from '@splidejs/vue-splide';
import {api} from '@/api';
import LandingFooter from '@/components/Footer/LandingFooter.vue';

@Component({
  components: {
    Bar,
    ElTable,
    ElTableColumn,
    VueSlider,
    Splide,
    SplideSlide,
    LandingFooter,
  },
})
export default class Pages extends Vue {
  public mainLanding = true;
  public name = 'light-table';
  public page: any = {
    title: '',
    content: '',
    show_title: true,
  };
  public title = '';
  public content = '';

  public async mounted() {
    const response = await api.getPageContent(this.$router.currentRoute.params.slug);
    if (response.status === 200) {
      this.page = response.data;
    }
    if (window.location.host === 'apikey.nl') {
      this.mainLanding = false;
    }
  }
  get signLink() {
    if (window.location.host === 'apikey.nl') {
      return this.$router.currentRoute.params.promoCode ?
        `https://my.apikey.nl/register?promocode=${this.$router.currentRoute.params.promoCode}&client=true` : `https://my.apikey.nl/register?client=true`;
    } else {
      return this.$router.currentRoute.params.promoCode ?
          `https://${window.location.host}/register?promocode=${this.$router.currentRoute.params.promoCode}` : `https://${window.location.host}/register`;
    }
  }
}

